// Sticky tabs
((document) => {
  // TODO: Remove this when the new menu is permanents
  const IS_NEW_NAV = localStorage.getItem('new_menu') === 'YES';
  // Get the elements
  const header = IS_NEW_NAV
    ? document.querySelector('.js-site-main-nav')
    : document.querySelector('.js-site-primary-nav');
  const nav = IS_NEW_NAV
    ? document.querySelector('.js-main-nav')
    : document.querySelector('.js-primary-nav');
  const overviewNav = document.querySelector('.js-overview-nav');
  const billboard = document.querySelector('.js-sticky-content-offset');
  const stickyTabs = document.querySelector('.js-sticky-tabs');
  const placeholder = document.querySelector('.js-tabs-placeholder');
  let tabItems = document.querySelectorAll('.js-sticky-tabs .tabs__item');
  const primaryNavUser = document.querySelector('.js-primary-nav__user');
  const mobileView = window.matchMedia('(max-width: 640px)');

  let hasAnyTabsBeenCLicked = false;

  // Get the height and distance values
  const headerHeight = header.getBoundingClientRect().height;
  const stickyTabsHeight = stickyTabs.getBoundingClientRect().height;
  let stickyTabsTop = stickyTabs.getBoundingClientRect().top;
  const tabsOrigPos =
    billboard.getBoundingClientRect().bottom - stickyTabsHeight + window.pageYOffset;

  function initTabObvserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const activeSectionId = entry.target.getAttribute('id');
          const activeTab = document.querySelector(`.tabs__item[href="#${activeSectionId}"]`);
          const isActiveTabClicked = activeTab.classList.contains('is-clicked');

          if (entry.isIntersecting) {
            if (!hasAnyTabsBeenCLicked) {
              activeTab.classList.add('is-active');
            }

            if (isActiveTabClicked) {
              hasAnyTabsBeenCLicked = false;
              activeTab.classList.remove('is-clicked');
            }

            tabItems.forEach((tabItem) => {
              if (tabItem !== activeTab && !isActiveTabClicked && !hasAnyTabsBeenCLicked) {
                tabItem.classList.remove('is-active');
              }
            });
          }
        });
      },
      { threshold: 1 },
    );

    tabItems.forEach((tab) => {
      const sectionId = tab.getAttribute('href');
      const section = document.querySelector(sectionId);

      if (section) {
        observer.observe(section);
      }
    });
  }

  stickyTabsTop = stickyTabs.getBoundingClientRect().top;

  if (stickyTabsTop <= headerHeight) {
    stickyTabs.classList.add('sticky-tabs--are-fixed');
  }

  if (window.scrollY + headerHeight < tabsOrigPos) {
    stickyTabs.classList.remove('sticky-tabs--are-fixed');
  }

  function stickyNavInit() {
    stickyTabsTop = stickyTabs.getBoundingClientRect().height;
    const stickyTabsTopOverview = stickyTabsTop / 2 + 5;
    const primaryNavUserHeight = primaryNavUser.getBoundingClientRect().height;
    const headerHeightNew = header.getBoundingClientRect().height;
    const navHeightNew = nav.getBoundingClientRect().height;

    if (stickyTabsTop <= headerHeightNew) {
      stickyTabs.classList.add('sticky-tabs--are-fixed');

      // When the user nav has the class of sticky get the height of it
      if (primaryNavUser && primaryNavUser.classList.contains('is-sticky')) {
        stickyTabs.style.top = `${primaryNavUserHeight}px`;
        placeholder.style.paddingTop = `${stickyTabsTop}px`;
      } else if (mobileView && nav.classList.contains('is-sticky')) {
        stickyTabs.style.top = `${navHeightNew}px`;
        placeholder.style.paddingTop = `${stickyTabsTop}px`;
      } else if (overviewNav) {
        stickyTabs.style.top = `${headerHeightNew}px`;
        placeholder.style.paddingTop = `${stickyTabsTopOverview}px`;
      } else {
        stickyTabs.style.top = `${headerHeightNew}px`;
        placeholder.style.paddingTop = `${stickyTabsTop}px`;
      }
    }

    if (window.scrollY + headerHeight < tabsOrigPos) {
      stickyTabs.classList.remove('sticky-tabs--are-fixed');
      stickyTabs.removeAttribute('style');
      placeholder.removeAttribute('style');
    }
  }

  let scrollingTimeout;

  window.addEventListener(
    'scroll',
    () => {
      // Clear timeout while still scrolling
      window.clearTimeout(scrollingTimeout);

      // Timeout runs after scrolling ends
      scrollingTimeout = setTimeout(() => {
        stickyNavInit();
      }, 50);
    },
    false,
  );

  function throttled(delay, fn) {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      // eslint-disable-next-line consistent-return
      return fn(...args);
    };
  }

  throttled(200, window.addEventListener('scroll', stickyNavInit));

  // Window resize function
  function hadleWindowResize() {
    stickyNavInit();
  }

  // Add a listener to resize
  let eventEnd;
  window.addEventListener('resize', () => {
    clearTimeout(eventEnd);
    eventEnd = setTimeout(hadleWindowResize, 100);
  });

  vm.onload(() => {
    if (stickyTabs.classList.contains('sticky-tabs--are-fixed')) {
      stickyTabs.classList.remove('sticky-tabs--are-fixed');
    }

    // Set tab items on load
    tabItems = document.querySelectorAll('.js-sticky-tabs .tabs__item');

    initTabObvserver();

    function tabItemsClick(tabClickEvent) {
      for (let i = 0; i < tabItems.length; i += 1) {
        tabItems[i].classList.remove('is-clicked');
      }
      const clickedTab = tabClickEvent.currentTarget;
      clickedTab.classList.add('is-clicked');

      hasAnyTabsBeenCLicked = true;
    }

    for (let i = 0; i < tabItems.length; i += 1) {
      tabItems[i].addEventListener('click', tabItemsClick);
    }
  });

  vm.jsImport('/js/modules/dist/smooth-scroll.js');

  // keep the chosen tab in viewport sight (mobile)
  document.addEventListener('DOMContentLoaded', () => {
    const activeTab = document.querySelector('.tabs__item.is-active');

    const tabRect = activeTab.getBoundingClientRect();

    if (tabRect.left < 0 || tabRect.right > window.innerWidth) {
      activeTab.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  });
})(document);
